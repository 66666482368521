<template>
  <div id="directions">
    <v-toolbar flat>
      <v-row dense>
        <v-col md="9">
          <v-text-field :value="filter.search" @change="value => filter.search = value" placeholder="例：商品ページ修正" filled rounded prepend-inner-icon="mdi-magnify" clearable hide-details dense ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-select dense clearable filled rounded hide-details persistent-placeholder class="ml-1" v-model="filter.status_id" :items="AllStatus" item-text="status" item-value="id" placeholder="ステータス"></v-select>
        </v-col>
      </v-row>
      <v-spacer />
    </v-toolbar>
    <v-container class="">
        <v-card>
          <v-data-table :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="directions" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" class="clickable" @click:row="changeView">
            <!-- 列：営業担当 -->
            <template v-slot:item.business_person_id="{ item }">
              <template v-if="item.business_person">
                <div style="white-space: nowrap;">
                  <LoginAvatar :user="item.business_person" :size="32" :avatarClass="['d-inline-block','mr-1']" />
                  {{ item.business_person.name }}
                </div>
              </template>
            </template>
            <!-- 列：担当ディレクター -->
            <template v-slot:item.direction_director_person_id="{ item }">
              <template v-if="item.direction_director_person">
                <div style="white-space: nowrap;">
                  <LoginAvatar :user="item.direction_director_person" :size="32" :avatarClass="['d-inline-block','mr-1']" />
                  {{ item.direction_director_person.name }}
                </div>
              </template>
            </template>
            <!-- 列：納品予定日 -->
            <template v-slot:item.direction_delivery_date="{ item }">
              <template v-if="item.direction_delivery_date">{{ item.direction_delivery_date | moment("YYYY/MM/DD(ddd)") }}</template>
            </template>
            <!-- 列：状況 -->
            <template v-slot:item.direction_status_mst_id="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="caption">{{ item?.direction_status?.status ?? '－' }}</div>
                </template>
                <span>{{ '進捗率：' + item.progress_percentage + '%' }}</span>
              </v-tooltip>
              <v-progress-linear rounded :color="item?.direction_status?.theme_color ?? 'gray'" :value="item.progress_percentage"></v-progress-linear>
            </template>
            <!-- 列：プラン名 -->
            <template v-slot:item.direction_type="{ item }">
              <template v-if="item.direction_type">{{ item.direction_type }}</template>
            </template>
            <!-- 列：案件種別 -->
            <template v-slot:item.direction_type2="{ item }">
              <template v-if="item.direction_type2">{{ item.direction_type2 }}</template>
            </template>
          </v-data-table>
        </v-card>
    </v-container>
  </div>
</template>

<script>
import LoginAvatar from '@/components/LoginAvatar.vue'
import axios from '@/plugins/axios'

export default {
  name: 'Directions',
  components: {
    LoginAvatar
  },
  data: () => ({
    key: {
      altKeyOn: false,
      shiftKeyOn: false,
      ctrlKeyOn: false,
      metaKeyOn: false
    },
    options: {},
    totalPassengers: 0,
    loading: false,
    filter: {
      search: '',
      status_id: null
    },
    headers: [
      {
        text: '',
        width: 110,
        value: 'direction_status_mst_id',
        align: 'center'
      },
      {
        text: 'プラン名',
        value: 'direction_type'
      },
      {
        text: '案件種別',
        value: 'direction_type2'
      },
      {
        text: '営業担当',
        value: 'business_person_id'
      },
      {
        text: '担当ディレクター',
        value: 'direction_director_person_id'
      },
      {
        text: '納品予定日',
        value: 'direction_delivery_date'
      }
    ],
    directions: []
  }),
  watch: {
    $route (to, from) {
      this.readDataFromAPI()
    },
    filter: {
      handler () {
        this.options.page = 1
        this.readDataFromAPI()
      },
      deep: true
    },
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  },
  computed: {
    AllStatus: function () {
      return this.$store.getters.getAllDirectionStatus
    }
  },
  methods: {
    changeView: function (item) {
      const routeOption = { name: 'client:direction_show', params: { id: item.id } }
      if (this.key.altKeyOn === true || this.key.shiftKeyOn === true || this.key.ctrlKeyOn === true || this.key.metaKeyOn === true) {
        window.open(this.$router.resolve(routeOption).href)
      } else {
        this.$router.push(routeOption)
      }
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'id' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'desc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      if (this.filter.search) {
        queryParams.query = this.filter.search
      }
      if (this.filter.status_id) {
        queryParams.status_id = this.filter.status_id
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction`, {
        params: queryParams
      })
        .then(res => {
          this.directions = res.data.data
          this.totalPassengers = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created: function () {
    const self = this

    // キーイベント監視
    self.keyDownHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKey
    }
    self.keyUpHandler = function (event) {
      self.key.altKeyOn = event.altKey
      self.key.shiftKeyOn = event.shiftKey
      self.key.ctrlKeyOn = event.ctrlKey
      self.key.metaKeyOn = event.metaKeyOn
    }
    window.addEventListener('keydown', self.keyDownHandler)
    window.addEventListener('keyup', self.keyUpHandler)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.keyDownHandler)
    window.removeEventListener('keyup', this.keyUpHandler)
  }
}
</script>
